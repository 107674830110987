'use client'

import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useSearchParams } from 'next/navigation';
import SectionHeader from '../common/SectionHeader';
import SectionFooter from '../common/SectionFooter';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { delWebsite } from '../../service';
import { ReactComponent as MiniEditIcon } from '../../icons/mini-edit.svg';
import { ReactComponent as MiniDelIcon } from '../../icons/mini-del.svg';
import { useManageProfileData } from '../../DataProvider';
import DeleteModal from '../common/Modal/DeleteModal';
import { useLanguage } from 'app/components/providers/languageProvider';
import { formatTemplateString } from 'helpers/formatter';
import EditLink from './EditLink';
import { useDeviceType, useGotoPage } from '../../util';
import RIf from 'components/RIf'

let showMaxNum = 3

const Links = (props) => {
  const searchParams = useSearchParams()
  const module = searchParams.get('module')
  const type = searchParams.get('type')
  const { profile: userProfile, config, fetchProfile, langDictionary } = useManageProfileData()
  const { id } = props
  const dataArr = userProfile?.websites || []
  const [operateType, setOperateType] = useState(null)
  const [currentItem, setCurrentItem] = useState({})
  const [deleteItem, setDeleteItem] = useState({})
  const [loading, setLoading] = useState(false)

  const { isMobile } = useDeviceType()

  const langDict = langDictionary || useLanguage()
  const { manageResume, btns_lang, modal_lang } = langDict

  const { gotoAddPage, gotoEditPage, gotoBackUrl } = useGotoPage()

  if (isMobile) showMaxNum = dataArr.length

  const handleAdd = () => {
    setOperateType('add')
    gotoAddPage(id)
  }

  const handleEdit = (item) => {
    setOperateType('edit')
    setCurrentItem(item)
    gotoEditPage(id)
  }

  const handleDelete = async () => {
    setLoading(true)
    await delWebsite(deleteItem)
    setLoading(false)
    setOperateType('')
    fetchProfile()
  }

  const handleCancel = () => {
    setOperateType(null)
    setCurrentItem({})
    gotoBackUrl()
  }

  const handleSubmit = () => {
    fetchProfile()
    setOperateType(null)
    setCurrentItem({})
    gotoBackUrl()
  }

  const showItem = (item) => {
    if (!isMobile) return item.id !== (currentItem as any).id
    else return true
  }

  const handleOpenDeleteModal = (item) => {
    setOperateType('delete')
    setDeleteItem(item)
  }
  const handleCloseDeleteModal = () => {
    setOperateType('')
  }

  // 根据module参数决定显示条数
  const itemsToDisplay = module ? dataArr : dataArr.slice(0, showMaxNum)

  return (
    <LinksWrap>
      {!type && (
        <div className='links-wrap'>
          <SectionHeader
            id={id}
            title={manageResume?.menu?.links || 'Links'}
            Icon={dataArr.length > 0 && AddIcon}
            onClick={handleAdd}
          />
          {operateType === 'add' && !isMobile ? (
            <EditLink
              operateType={operateType}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              config={config}
            />
          ) : null}
          {itemsToDisplay.length > 0 &&
            itemsToDisplay.map((item, index) => {
              return (
                <div key={index} className='item-wrap'>
                  {operateType === 'edit' && !isMobile && item.id === (currentItem as any).id ? (
                    <EditLink
                      initialValues={currentItem}
                      operateType={operateType}
                      onCancel={handleCancel}
                      onSubmit={handleSubmit}
                      config={config}
                    />
                  ) : null}
                  {showItem(item) ? (
                    <div className='item'>
                      <div className='block-title'>
                        <div className='link-title'>
                          <a
                            href={!isMobile ? item.url : ''}
                            target='_blank'
                            style={{ textDecoration: 'underline' }}
                          >
                            {item.title || item.url}
                          </a>
                        </div>
                        <div className={isMobile && module ? 'ope-btns ope-btns-h5' : 'ope-btns'}>
                          <span className='btn-edit' onClick={() => handleEdit(item)}>
                            <MiniEditIcon />
                          </span>
                          <span
                            className='btn-del'
                            onClick={() => handleOpenDeleteModal(item)}
                            style={{ marginLeft: 16 }}
                          >
                            <MiniDelIcon />
                          </span>
                        </div>
                      </div>
                      {item.description && (
                        <div className='des'>
                          <div dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                      )}
                    </div>
                  ) : null}
                  {module && index < itemsToDisplay.length - 1 && <div className='divider' />}
                  {!module && index < dataArr.length - 1 && <div className='divider' />}
                </div>
              )
            })}
          {((!operateType && !isMobile) || isMobile) && !dataArr.length ? (
            <div className='empty'>
              <p className='description'>{manageResume.menu.links_tip}</p>
              <RIf show={!isMobile}>
                <span className='add-btn' onClick={handleAdd}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                  >
                    <path
                      d='M9.75 3C9.75 2.58579 9.41421 2.25 9 2.25C8.58578 2.25 8.25 2.58579 8.25 3V8.25H3C2.58579 8.25 2.25 8.58578 2.25 9C2.25 9.41421 2.58579 9.75 3 9.75H8.25V15C8.25 15.4142 8.58578 15.75 9 15.75C9.41421 15.75 9.75 15.4142 9.75 15V9.75H15C15.4142 9.75 15.75 9.41421 15.75 9C15.75 8.58578 15.4142 8.25 15 8.25H9.75V3Z'
                      fill='#111212'
                    />
                  </svg>
                  <span>{manageResume.link.add || 'Add link'}</span>
                </span>
              </RIf>
            </div>
          ) : null}
          {dataArr.length > showMaxNum && !module && (
            <SectionFooter
              moduleId={id}
              moreText={formatTemplateString(manageResume.link.more_text, {
                length: dataArr.length
              })}
            />
          )}
        </div>
      )}
      {type && (
        <div className='links-wrap links-wrap-with-type'>
          <SectionHeader
            id={id}
            title={type === 'add' ? manageResume.link.add : manageResume.link.edit}
          />
          <EditLink
            initialValues={currentItem}
            onCancel={handleCancel}
            config={config}
            operateType={type}
            onSubmit={handleSubmit}
          />
        </div>
      )}
      <DeleteModal
        open={operateType === 'delete'}
        onCancel={handleCloseDeleteModal}
        onOk={handleDelete}
        cancelText={btns_lang.cancel || 'Cancel'}
        okText={btns_lang.delete || 'Delete'}
        title={manageResume.link.delete_modal_title || 'Delete this language？'}
        content={
          modal_lang.delete_content || 'Please note that deleted content cannot be restored.'
        }
        maskClosable={false}
        closable={false}
        loading={loading}
      />
    </LinksWrap>
  )
}

export default Links

const LinksWrap = styled.div`
  .links-wrap {
    background-color: #ffffff;
    padding-bottom: 16px;
    border-radius: 16px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 767px) {
      border-radius: 12px;
    }
  }

  .links-wrap-with-type {
    padding-bottom: 0;
  }

  /* .item-wrap {
    @media (min-width: 768px) {
      &:hover {
        background-color: #f5f7fb;
      }
    }
  } */

  .item {
    padding: 16px 24px;
    &:hover {
      .ope-btns {
        @media (min-width: 768px) {
          visibility: visible;
          cursor: pointer;
        }
      }
    }

    .block-title {
      display: flex;
      justify-content: start;
      align-items: center;

      .link-title {
        font-style: normal;
        line-height: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        padding-bottom: 2px;
        a {
          text-underline-offset: 4px;
          color: #121212;
          font-size: 14px;
          font-weight: 400;
          &:hover {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    .ope-btns {
      display: flex;
      margin-left: auto;
      visibility: hidden;
      white-space: nowrap;
      .btn-edit,
      .btn-del {
        display: flex;
        align-items: center;
        &:hover {
          svg {
            path {
              fill: #89bdff;
            }
          }
        }
      }
    }

    .ope-btns-h5 {
      visibility: visible;
    }

    .des {
      margin-top: 8px;
      color: #515151;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .divider {
    height: 1px;
    background-color: #eaeaea;
    margin: 0 24px;
  }

  .empty {
    padding: 16px 24px;
  }

  .description {
    margin-bottom: 16px;
    color: #7d7d7d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .add-btn {
    display: inline-flex;
    height: 40px;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background: #f8f9fa;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    &:hover {
      background: #f4f5f6;
    }
  }
`