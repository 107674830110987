import { Button } from "antd";
import { FooterInBoardPropsType } from "../../type";
import styles from "../../style/index.module.scss";

const FooterInBoard = ({
  value,
  setValue,
  onChange,
  setSearchValue,
  onCloseDropdown,
  boardClearText,
  boardApplyFiltersText,
  total
}: FooterInBoardPropsType) => {
  const handleReset = () => {
    onCloseDropdown();
    setSearchValue("");
    setValue([]);
    onChange([], []);
  };

  const handleApplyFilter = () => {
    onCloseDropdown();
    onChange(value, []);
  };

  const length = total ? `(${total})` : "";

  return (
    <div>
      <div className={styles.footer_in_board_divider_wrapper}>
        <div className={styles.divider} />
      </div>
      <div className={styles.footer_in_board}>
        {/*  */}
        <Button onClick={handleReset} type='link' className={styles.footer_in_board_left}>
          {boardClearText || "Clear"}
        </Button>

        <Button type='primary' onClick={handleApplyFilter} className={styles.footer_in_board_right}>
          {boardApplyFiltersText || "Apply filter"} {length}
        </Button>
      </div>
    </div>
  );
};

export default FooterInBoard;
