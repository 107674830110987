import { Card, CommWrapper } from "../card";

import Image from "next/image";
import { Tooltip } from "antd";
import { useCompanyDetail } from "../../../DataProvider";
import { Fragment, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import styles from "./index.module.scss";
import {
  CommContent,
  CommContentLayout
} from "app/(new-pages)/(company-detail)/[lang]/company/[keyword]/_pc/components/Layout/index.pc";
import { CommModal } from "../comm/Modal";
import { CultureAndLifeItem } from "../comm/cultureAndLifeItem";

const VerificationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M9.30027 0.685356C9.68905 0.304565 10.311 0.304565 10.6997 0.685356L12.6594 2.60476C12.8437 2.78531 13.0907 2.88762 13.3487 2.8903L16.0917 2.91877C16.6358 2.92442 17.0756 3.36417 17.0812 3.90834L17.1097 6.65125C17.1124 6.90927 17.2147 7.15627 17.3952 7.34061L19.3146 9.30027C19.6954 9.68905 19.6954 10.311 19.3146 10.6997L17.3952 12.6594C17.2147 12.8437 17.1124 13.0907 17.1097 13.3487L17.0812 16.0917C17.0756 16.6358 16.6358 17.0756 16.0917 17.0812L13.3487 17.1097C13.0907 17.1124 12.8437 17.2147 12.6594 17.3952L10.6997 19.3146C10.311 19.6954 9.68905 19.6954 9.30027 19.3146L7.34061 17.3952C7.15627 17.2147 6.90927 17.1124 6.65125 17.1097L3.90834 17.0812C3.36417 17.0756 2.92442 16.6358 2.91877 16.0917L2.8903 13.3487C2.88762 13.0907 2.78531 12.8437 2.60476 12.6594L0.685356 10.6997C0.304565 10.311 0.304565 9.68905 0.685356 9.30027L2.60476 7.34061C2.78531 7.15627 2.88762 6.90927 2.8903 6.65125L2.91877 3.90834C2.92442 3.36417 3.36417 2.92442 3.90834 2.91877L6.65125 2.8903C6.90927 2.88762 7.15627 2.78531 7.34061 2.60476L9.30027 0.685356Z'
        fill='#4BD9E3'
      />
      <path
        d='M6.54028 9.61217L9.08398 12.1696L13.4535 7.82938'
        stroke='black'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const PCHeader = ({ companyHeaderData }) => {
  const { companyPageData = {} } = useCompanyDetail();

  const { industry, company_size } = companyPageData?.company_basic || {};

  const tags = [
    {
      label: industry
    },
    {
      label: company_size
    }
  ].filter((v) => Boolean(v.label));

  const stickyRef = useRef(null);
  const [attachTop, setAttachTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const offsetTop = stickyRef.current.getBoundingClientRect().top;
        setAttachTop(offsetTop <= 0);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CommContentLayout>
      <div
        style={{
          borderRadius: 16
        }}
      >
        <CommContent style={{ padding: 16, borderRadius: "16px", backgroundColor: "#f8f9fa" }}>
          <div>
            <div
              className={styles.headerRoot}
              style={{
                marginTop: 55,
                padding: "48px 20px 20px 20px",
                borderRadius: 16,
                position: "relative",
                backgroundColor: "#fff"
              }}
            >
              <Image
                width={90}
                height={90}
                style={{
                  position: "absolute",
                  top: -50,
                  boxShadow: "0px 5px 8px -2px rgba(162, 183, 203, 0.10)",
                  border: "3px solid #fff",
                  borderRadius: 16
                }}
                className={styles.companyLogo}
                src={companyHeaderData.logo}
                alt='logo'
              />

              <div className={styles.companyName}>
                {companyHeaderData.name}
                {companyHeaderData.is_verify && (
                  <Tooltip title={companyPageData?.company_header?.verify_text}>
                    <span className={""} style={{ display: "flex", alignItems: "center" }}>
                      <VerificationIcon />
                    </span>
                  </Tooltip>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 6
                }}
              >
                <div className={styles.companySizeWrapper}>
                  {tags.map((v, i) => {
                    const { label } = v;
                    return (
                      <Fragment key={i}>
                        {Boolean(i) && <span className={styles.dot} />}
                        <div>{label}</div>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </CommContent>
      </div>
    </CommContentLayout>
  );
};
